import React from "react"
import { Container, Row, Form } from "react-bootstrap"
import Layout from "../components/utils/layout"
import SEO from "../components/utils/seo"
import TitleLine from "../components/utils/TitleLine"
import "../styles/SignUpPage.css"
import { navigate } from "gatsby"
import Loader from "../components/utils/Loader.js"
import getUserData from "../scripts/getUserData.js"
import NewAsyncButton from "../components/utils/NewAsyncButton.js"
import sendSlackInquiry from "../scripts/sendSlackInquiry.js"
import { db, auth } from "../firebase"
import dots from "../images/bgs/dots.svg"

class SendResetEmail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      authenticated: null,
      message: "",
      userData: null,
      hasSubmittedInquiry: false,
      inquiryID: null,
    }
    this.handleChange = this.handleChange.bind(this)
  }

  inquiryExists = () => {
    let exists = false
    let inquiryID = null
    return Promise.all([
      db
        .collection("slackNotifs")
        .where("id", "==", window.history.state.taskID)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(function(doc) {
            exists = true
            inquiryID = doc.id
          })
        })
        .catch(error => {
          console.log(error)
        }),
    ]).then(() => {
      return Promise.resolve([exists, inquiryID])
    })
  }

  componentDidMount() {
    auth.onAuthStateChanged(user => {
      if (!user) {
        navigate("/404/")
      } else {
        getUserData(user.uid).then(data => {
          if (data["userType"] !== "teacher" || !user.emailVerified) {
            navigate("/404/")
          } else {
            this.inquiryExists().then(res => {
              this.setState({
                authenticated: true,
                userData: data,
                hasSubmittedInquiry: res[0],
                inquiryID: res[1],
              })
            })
          }
        })
      }
    })
  }

  sendMessage = reset => {
    let message = {
      tag: "hoursInquiry",
      teacherName:
        this.state.userData.firstName + " " + this.state.userData.lastName,
      teacherSchool: this.state.userData.school,
      teacherEmail: this.state.userData.email,
      teacherMessage: this.state.message,
      id: window.history.state.taskID,
    }

    sendSlackInquiry(
      message,
      this.state.hasSubmittedInquiry,
      this.state.inquiryID
    ).then(res => {
      if (res) {
        reset("Success!")
      } else {
        reset("An error occured. Try again?")
      }
    })
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    if (this.state.authenticated && this.state.userData) {
      return (
        <Layout image={dots} full>
          <SEO title="Hours Inquiry" />
          <Container className="topSpacing">
            <TitleLine
              title="Volunteer Hours Inquiry"
              description="If your student has submitted a voluteer hours log that is faulty/has irregularities, please describe the situation briefly below. This will send a direct message to our management team. We guarantee your issue will be addressed and you will be notified of it's status in 24 hours."
            />
            {this.state.hasSubmittedInquiry && (
              <Row
                className="justify-content-center"
                style={{ marginBottom: "30px" }}
              >
                <h8>
                  <i>
                    You have already filed an inquiry for this project.
                    Submitted a new inquiry will replace the previous inquiry.
                  </i>
                </h8>
              </Row>
            )}

            <Form>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>
                  Describe the situation, what is incorrect about the student's
                  volunteer hours log?
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  placeholder="Start typing"
                  onChange={this.handleChange}
                  name="message"
                />
              </Form.Group>
            </Form>
            <Row
              className="justify-content-center sidesPadding"
              style={{ marginTop: "30px" }}
            >
              <NewAsyncButton
                buttonVariants={["primary", "primary", "danger", "primary"]}
                buttonTexts={[
                  "Send Message",
                  "Sending...",
                  "Unable to send. Try again?",
                  "Message Sent! Expect a response in 24 hours.",
                ]}
                buttonColor={["", "", "", ""]}
                callback={this.sendMessage}
              />
            </Row>
          </Container>
        </Layout>
      )
    } else {
      return (
        <Layout full>
          <SEO title="Password Reset" />
          <Container>
            <Row className="justify-content-center">
              <Loader />
            </Row>
          </Container>
        </Layout>
      )
    }
  }
}

export default SendResetEmail
