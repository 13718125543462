import { db } from "../firebase"

export default async function sendSlackInquiry(
  message,
  inquiryExists,
  inquiryID
) {
  if (!inquiryExists) {
    return db
      .collection("slackNotifs")
      .add(message)
      .then(function(docRef) {
        return Promise.resolve(true)
      })
      .catch(function(error) {
        return Promise.resolve(false)
      })
  } else {
    return db
      .collection("slackNotifs")
      .doc(inquiryID)
      .set(message)

      .then(function() {
        return Promise.resolve(true)
      })
      .catch(function(error) {
        return Promise.resolve(false)
      })
  }
}
